var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rocket-review-game-container layout-border" },
    [
      _c("audio", { ref: "audioBox", attrs: { src: _vm.audioUrl } }),
      _c(
        "div",
        { staticClass: "customlesson-content-five position-relative" },
        [
          _c("PageButton", {
            attrs: {
              bottomCornerButtonList: _vm.bottomCornerButtonList,
              isLastStep: _vm.isLastStep,
            },
            on: { clickNext: _vm.nextPage },
          }),
          _c(
            "div",
            {
              ref: "backgroundArea",
              staticClass: "rocket-bg-area",
              attrs: { id: "space-background-scroll" },
            },
            [
              _c("img", {
                ref: "content",
                staticClass: "bg-img",
                attrs: { src: _vm.bcgImg, alt: "" },
              }),
              _c(
                "div",
                {
                  ref: "rocketTop",
                  staticClass: "rocket-area",
                  class: {
                    fadeOutRocket: _vm.fadeOutRocket,
                    fixPosition: _vm.startMove,
                  },
                },
                [
                  _c("img", {
                    staticClass: "rocket-img",
                    class: { isHidden: _vm.animationFrame2 != 1 },
                    attrs: {
                      src: require("@/assets/img/16-GAMES/G09-submarine/submarine.svg"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    staticClass: "rocket-img",
                    class: { isHidden: _vm.animationFrame2 != 2 },
                    attrs: {
                      src: require("@/assets/img/16-GAMES/G09-submarine/submarine-2.svg"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    staticClass: "rocket-img",
                    class: { isHidden: _vm.animationFrame2 != 3 },
                    attrs: {
                      src: require("@/assets/img/16-GAMES/G09-submarine/submarine-3.svg"),
                      alt: "",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "bubbles",
                      class: { isHidden: _vm.hasReachedStation },
                    },
                    [
                      _c("img", {
                        staticClass: "bubble b2",
                        attrs: {
                          src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                        },
                      }),
                      _c("img", {
                        staticClass: "bubble b5",
                        attrs: {
                          src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                        },
                      }),
                      _c("img", {
                        staticClass: "bubble",
                        attrs: {
                          src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                        },
                      }),
                      _c("img", {
                        staticClass: "bubble b6",
                        attrs: {
                          src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                        },
                      }),
                      _c("img", {
                        staticClass: "bubble b3",
                        attrs: {
                          src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                        },
                      }),
                      _c("img", {
                        staticClass: "bubble b7",
                        attrs: {
                          src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                        },
                      }),
                      _c("img", {
                        staticClass: "bubble b4",
                        attrs: {
                          src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                        },
                      }),
                      _c("img", {
                        staticClass: "bubble b8",
                        attrs: {
                          src: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/88939/drop.png",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "panel-area" }, [
            _c("img", {
              staticClass: "panel-img",
              attrs: {
                src:
                  _vm.trigger == "button"
                    ? require("@/assets/img/16-GAMES/G09-submarine/submarine-panel-no-button.svg")
                    : require("@/assets/img/16-GAMES/G09-submarine/submarine-panel.svg"),
                alt: "",
              },
            }),
            _vm.trigger == "button"
              ? _c(
                  "div",
                  {
                    staticClass: "control-area-button glow",
                    class: {
                      isHover: !_vm.hasReachedStation && _vm.steps != 0,
                    },
                    on: { click: _vm.controlClick },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G09-submarine/submarine-control-button.svg"),
                        alt: "",
                      },
                    }),
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass: "control-area glow",
                    on: { click: _vm.controlClick },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G09-rocket/control.svg"),
                        alt: "",
                      },
                    }),
                  ]
                ),
          ]),
        ],
        1
      ),
      false
        ? _c("div", { staticClass: "customlesson-finger" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/img/16-GAMES/G09-rocket/hand.svg"),
                alt: "",
              },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }