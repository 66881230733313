<template>
  <div class="game-container">
    <div class="write-hanzi-game-container border-layout">
      <div class="red-background-border"></div>
      <div class="write-hanzi-game-box">
        <PageButton
          :bottomCornerButtonList="bottomCornerButtonList"
          :isLastStep="isLastStep"
          @clickNext="nextPage"
          @click.native="handleWrite('socket')"
        ></PageButton>
        <!-- <SceneSwitchThumbnails
          :buttons="buttonList"
          :numBers="currentIndex"
          @changeTheNumbers="changeTheNumbers"
        /> -->
        <div
          class="write-hanzi-button"
          :class="{
            btnEyeHover: isBtnEyeHover,
            btnRestartHover: isBtnRestartHover,
          }"
        >
          <!-- 眼睛按钮 eye-->

          <div
            class="btn-item eye-btn"
            @click="handleClickEyeButton('socket')"
            @mouseenter="isBtnEyeHover = true"
            @mouseleave="isBtnEyeHover = false"
          >
            <img
              v-if="isShowTips"
              src="@/assets/img/16-GAMES/G20-writing-hanzi/button-eye-on.svg"
              alt=""
            />
            <img
              v-else
              src="@/assets/img/16-GAMES/G20-writing-hanzi/button-eye-off.svg"
              alt=""
            />
          </div>

          <!-- 重玩按钮 eye-->
          <div
            class="btn-item restart-btn"
            @click="restartGame('socket')"
            @mouseenter="isBtnRestartHover = true"
            @mouseleave="isBtnRestartHover = false"
          >
            <img
              v-if="!eyeIsHover"
              src="@/assets/img/16-GAMES/G20-writing-hanzi/button-restart.svg"
              alt=""
            />
            <img
              v-else
              src="@/assets/img/16-GAMES/G20-writing-hanzi/button-restart.svg"
              alt=""
            />
          </div>
        </div>

        <div class="write-hanzi-game-content layout-red-bgc">
          <SceneSwitchThumbnails
            :buttons="buttonList"
            :numBers="currentIndex"
            @changeTheNumbers="changeTheNumbers"
          />
          <div class="write-hanzi-game-content-container">
            <!-- 左边pic -->
            <div class="write-game-content-left-box">
              <div class="steps-img-box">
                <template v-for="(item, index) in LeftImgList">
                  <div
                    class="img-item"
                    :key="index"
                    v-if="index === currentIndex"
                  >
                    <img alt="" class="bg-tip-img" :src="item" />
                  </div>
                </template>
              </div>
            </div>
            <!-- 右边区域 -->
            <div class="write-game-content-right-box">
              <div class="blue-background-border">
                <div class="write-game-right-content">
                  <div class="hanzi-write-area">
                    <span class="pinyin font-pinyin-large">{{
                      currentHanziInfo.pinyin
                    }}</span>
                    <!-- <div class="stroke-square-box">
                      <img
                        class="stroke-square-box"
                        src="@/assets/img/16-GAMES/G20-writing-hanzi/background-square.svg"
                        alt=""
                      />
                    </div> -->
                    <div class="hengIcon">
                      <img
                        class="stroke-square-box"
                        :src="currentHanziInfo.bgImg"
                      />
                    </div>
                    <div class="hengIcon-gif" v-if="isShowTips">
                      <img
                        class="stroke-square-box"
                        v-for="(item, index) in gifList[currentIndex]"
                        :key="index"
                        :src="item"
                        v-show="index + 1 === currentStrokeNum"
                      />
                    </div>
                    <div
                      class="canvas-box"
                      @click="handleSingleClick('socket')"
                    >
                      <KidsWhiteBoard v-if="showCanvas" />
                    </div>
                  </div>
                  <div
                    class="hanzi-stroke-tips-area font-hanzi-medium"
                    v-if="isShowTips"
                    :class="{hasSixItems : currentHanziInfo.tipsList.length % 6 == 0}"
                  >
                    <template
                      v-for="(item, index) in currentHanziInfo.tipsList"
                    >
                      <span
                        :key="index + 'tips'"
                        class="hanzi-blue font-hanzi-medium"
                        :class="{
                          'hanzi-grey': index === currentHanziInfo.tipsIndex,
                        }"
                        >{{ item }}</span
                      >
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import KidsWhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue"; // 白板组件件
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
export default {
  name: "WriteHanziReviewPage",
  components: {
    KidsWhiteBoard,
    PageButton,
    SceneSwitchThumbnails,
  },
  props: {
    LeftImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    hanziInfoList: {
      type: Array,
      require: true,
      default: () => [],
    },
    gifList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  created() {
    this.currentHanziInfo = this.hanziInfoList[this.currentIndex];
  },
  data() {
    return {
      currentHanziInfo: {},
      socketInfo: {},
      initScoket: {},
      startSocketInfo: {},
      tipsSocketInfo: {},
      finishSingleSocketInfo: {},
      clickMenuSocketInfo: {},

      eyeIsHover: false,
      isBtnEyeHover: false,
      isBtnRestartHover: false,
      isShowTips: true,

      currentIndex: 0, //
      currentStrokeNum: 0, // 笔顺的的index
      isEyeOpen: true,
      showCanvas: false, // 使用canvas
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "draw",
            startNumberRange: 1,
            endNumberRange: 3,
          },
        ],
      },
      buttonList: [
        {
          image: require("@/assets/img/01-Menu/menu-write-1.svg"),
          startNumberRange: 0,
          endNumberRange: 0,
        },
        {
          image: require("@/assets/img/01-Menu/menu-write-2.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/01-Menu/menu-write-3.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
      ],
    };
  },

  beforeDestroy() {
    this.$bus.$off("clickDrawBtnReview", false);
    this.$bus.$off("writeHanziStepsReview", false);
    this.$bus.$off("clickTipsReview", false);
    this.$bus.$off("clickInitReview", false);
    this.$bus.$off("finishSingleHanziReview", false);
    this.$bus.$off("writeHanziReviewClickMenu");
    this.$bus.$emit("startStrokePage", false);
  },
  mounted() {
    this.$bus.$on("clickDrawBtnReview", () => {
      this.handleWrite("socket", true);
    });
    this.$bus.$on("writeHanziStepsReview", () => {
      this.handleSingleClick("socket", true);
    });
    this.$bus.$on("clickTipsReview", () => {
      this.handleClickEyeButton("socket", true);
    });
    this.$bus.$on("clickInitReview", () => {
      this.restartGame("socket", true);
    });
    this.$bus.$on("finishSingleHanziReview", () => {
      this.finishiSingleHanzi("socket", true);
    });
    this.$bus.$on("writeHanziReviewClickMenu", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
  },
  watch: {
    startSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002801,
          data: { value },
          text: "writeHanzistart按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    socketInfo: {
      handler(value) {
        const data = {
          clickType: 2002802,
          data: { value },
          text: "writeHanzi写一笔按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    tipsSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002803,
          data: { value },
          text: "writeHanzitips按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    initScoket: {
      handler(value) {
        const data = {
          clickType: 2002804,
          data: { value },
          text: "writeHanzi Init按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    finishSingleSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002805,
          data: { value },
          text: "writeHanzi Init按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002806,
          data: { value },
          text: "writeHanzi click menu socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      // console.log(index);
      this.currentIndex = index;
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.currentHanziInfo = this.hanziInfoList[this.currentIndex];
      this.$bus.$emit("controlCanvas", "clear");
      this.initGame();
    },
    initGame() {
      this.isShowTips = true;
      this.currentStrokeNum = 0;
      this.showCanvas = false;
      this.$bus.$emit("startStrokePage", false);
      setTimeout(() => {
        this.showCanvas = true;
        this.$bus.$emit("startStrokePage", true);
        this.currentStrokeNum++;
      }, 50);
    },
    restartGame(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.initScoket = { index: Math.random() };
      }
      this.isShowTips = true;
      this.currentStrokeNum = 0;
      this.showCanvas = false;
      this.$bus.$emit("startStrokePage", false);
      setTimeout(() => {
        this.showCanvas = true;
        this.$bus.$emit("startStrokePage", true);
        this.currentStrokeNum++;
      }, 50);
    },
    handleClickEyeButton(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.tipsSocketInfo = { index: Math.random() };
      }
      this.isShowTips = !this.isShowTips;
    },
    handleSingleClick(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.socketInfo = { index: Math.random() };
      }
      setTimeout(() => {
        this.currentStrokeNum++;
        this.finishiSingleHanzi(socket, isFromSocket);
      }, 500);
    },
    finishiSingleHanzi(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.finishSingleSocketInfo = {
          ndex: Math.random(),
        };
      }
      if (this.currentStrokeNum > this.currentHanziInfo.stepNumber) {
        console.log("书写完成");
        // this.$bus.$emit("controlCanvas", "clear");
        // this.initGame(socket, isFromSocket);

        // this.currentIndex++;
        console.log(this.currentIndex, "++++++++++++++++");

        // console.log(this.isLastStep);
        if (this.currentIndex > this.hanziInfoList.length - 1) {
          this.currentIndex = this.hanziInfoList.length - 1;
          // todolist: 成功音效
          this.isLastStep = true;
        }
        this.currentHanziInfo = this.hanziInfoList[this.currentIndex];
      }
    },
    // 开始书写
    handleWrite(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.startSocketInfo = { index: Math.random() };
      }
      console.log("开始书写");
      this.showCanvas = true;
      this.currentStrokeNum++;
      this.$bus.$emit("startStrokePage", true);
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
<style lang="scss" scoped>
.write-hanzi-game-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.write-hanzi-game-box {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background: #fff;
  border-radius: 58px;
  z-index: 2;
  position: relative;
  .write-hanzi-button {
    width: 9%;
    position: absolute;
    left: 5%;
    bottom: 10%;
    z-index: 5;
    background-image: url("../../../../assets/img/16-GAMES/G20-writing-hanzi/button-background.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .btn-item {
      width: 100%;
      height: 50%;
      // padding: 1%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 50px;
        padding: 20px 12px;
        cursor: pointer;
      }
    }
    .eye-btn img {
      padding-bottom: 10px;
    }
    .restart-btn img {
      padding-top: 10px;
    }
  }
  .btnEyeHover {
    background-image: url("../../../../assets/img/16-GAMES/G20-writing-hanzi/button-background-top-hover.svg");
  }
  .btnRestartHover {
    background-image: url("../../../../assets/img/16-GAMES/G20-writing-hanzi/button-background-bottom-hover.svg");
  }
  .restart-btn {
    bottom: 10px;
    right: 30px;
  }
  .btnHover {
    background-image: url("../../../../assets/img/16-GAMES/G20-writing-hanzi/button-background-hover.svg");
  }
  .restart-btn {
    bottom: 10px;
    right: 30px;
  }
}
.write-hanzi-game-content {
  background-color: #f7f4e0;
  border-radius: 58px;
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  .write-hanzi-game-content-container {
    width: 80%;
    height: 100%;
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .write-game-content-right-box {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .blue-background-border {
      margin-top: 10px;
      margin-left: 10px;
      width: calc(40% - 10px);
      height: calc(50% - 10px);
      position: absolute;
      // left: 14%;
      // top: 13%;
      background: #224e96;
      border-radius: 44px;
      min-height: 500px;
      max-width: 400px;
      max-height: 600px;
      .write-game-right-content {
        color: #000;
        text-align: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fff;
        border-radius: 44px;
        top: -10px;
        left: -10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: default;
        min-height: 500px;
        max-width: 400px;
        max-height: 600px;
        .hanzi-write-area {
          width: 70%;
          height: 70%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .pinyin {
            position: absolute;
            top: 3%;
            color: #cd4c3f;
          }
          .hengIcon,
          .hengIcon-gif {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 100%;
            img {
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto;
            }
          }
          .hengIcon {
            img {
              width: 100%;
            }
          }
          .stroke-square-box {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 100%;
            img {
              width: 100%;
              // height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              margin: auto;
            }
          }
          .stroke-square-box {
            cursor: pointer;
            max-width: 250px;
          }

          div {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
          }
          img {
            width: 100%;
          }
        }
        .hanzi-stroke-tips-area {
          width: 70%;
          height: 10%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 0 10%;
          img {
            width: 100%;
          }
          span {
            display: inline-block;
            width: 20%;
          }
          .hanzi-blue {
            color: #224e96;
          }
          .hanzi-grey {
            color: #f1f1f0;
          }

          &.hasSixItems {
            .hanzi-blue {
              width: 16%;
            }
          }
        }
      }
    }
  }
  .write-game-content-left-box {
    width: 100%;
    height: 100%;
    flex: 1;
    position: relative;
    .steps-img-box {
      width: 100%;
      height: 80%;
      display: flex;
      // align-items: center;
      // justify-content: flex-start;
      .img-item {
        width: 80%;
        display: flex;
      }

      .bg-tip-img {
        width: 100%;
      }
    }
    .action-button-box {
      width: 90px;
      height: 180px;
      // width:20%;
      // height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 88px;
      right: 5px;
      z-index: 100;
      .backgroundImg {
        width: 60%;
        position: absolute;
        right: 20%;
        bottom: 20%;
      }
      .action-button-box-top,
      .action-button-box-bottom {
        z-index: 9999;
        width: 60%;
        img {
          width: 80%;
        }
      }
    }
  }
}
</style>
